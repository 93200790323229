* {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

h1,
h2,
h3,
h4 {
  font-family: 'Proza Libre'
}

h1 {
  font-size: 2.3em;
}

.container {
  margin: 10px auto;
  max-width: 600px;
  text-align: center;
}